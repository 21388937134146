* {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif !important;
}

:root {
  /* --purple: #870058; */
  --purple: #00438b;
  --silver: #ebf0f573;
  --white: #ffff;
}

/* NavBar */
.css-h4y409-MuiList-root {
  padding-top: 60px !important;
}

.MuiList-root.MuiList-padding.css-h4y409-MuiList-root {
  padding: 0 !important;
}

/* Button */
.css-go3tnm-MuiButtonBase-root-MuiButton-root {
  background-color: var(--purple) !important;
}

.css-1542sqp-MuiButtonBase-root-MuiButton-root {
  background-color: var(--purple) !important;

}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: var(--purple) !important;
  /* color: var(--silver) !important; */
  font-weight: bold;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
  background-color: var(--purple) !important;

}

.css-se6t7u-MuiButtonBase-root-MuiButton-root {
  color: var(--purple) !important;
  font-weight: bold;
  border-color: var(--purple) !important;
}

.css-1ckq8tj-MuiButtonBase-root-MuiButton-root {
  background-color: var(--purple) !important;

}

/* Loader */
.loader {
  position: relative;
  width: 100px;
  height: 16px;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--white);
  box-shadow: 32px 0 var(--white);
  left: 0;
  top: 0;
  animation: ballMoveX 2s linear infinite;
}

.loader:after {
  box-shadow: none;
  transform: translateX(64px) scale(1);
  z-index: 2;
  animation: none;
  animation: trfLoader 2s linear infinite;
}

@keyframes trfLoader {

  0%,
  5% {
    transform: translateX(64px) scale(1);
    background: var(--white);
  }

  10% {
    transform: translateX(64px) scale(1);
    background: var(--purple);
  }

  40% {
    transform: translateX(32px) scale(1.5);
    background: var(--purple);
  }

  90%,
  95% {
    transform: translateX(0px) scale(1);
    background: var(--purple);
  }

  100% {
    transform: translateX(0px) scale(1);
    background: var(--white);
  }
}

@keyframes ballMoveX {

  0%,
  10% {
    transform: translateX(0)
  }

  90%,
  100% {
    transform: translateX(32px)
  }
}

.content-align-outside-comp {
  background-color: var(--silver);
  min-height: 87.7vh;
  height: 100%;
  padding: 20px;
  margin: -24px;
  position: relative;
}

.content-height {
  height: 100%;
}

.content-flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.content-room-book-id {
  border: 2px solid var(--purple);
  color: var(--purple);
  text-align: center;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  font-family: monospace;
  padding: 20px;
  font-size: min(max(13px, 1vw), 20px)
}

/* Tabs */


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: var(--purple) !important;
}

/* Select React */
.css-13cymwt-control {
  margin-bottom: 30px;
}

.css-a5rdam-MuiGrid-root>.MuiGrid-item {
  padding-top: 2px !important;
}

.hotelbook-side {
  display: none;
}

.hotelbook-show {
  display: block;
  position: absolute !important;
  z-index: 1000 !important;
  top: 130px;
  width: 97%;
  height: 400px !important;

}

.menu-icon {
  display: none;
  cursor: pointer;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}